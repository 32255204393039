/* -------------------------------------------------------------------------
 * TABS VARS
 *
 */

$c-tabs__nav-item--font-properties:                                   map_get($m-heading__mod-sizes, "tiny") !default;
$c-tabs__nav-item--bg-color:                                          $f-color__brand-primary--ultra-light !default;

$c-tabs__nav--bg-color:                                               $f-color__brand-primary--ultra-light !default;

$c-tabs__nav-button--color--open:                                     $f-color__brand-primary !default;
$c-tabs__nav-button--color--hover:                                    $f-color__brand-accent !default;
$c-tabs__nav-button--bg-color--hover:                                 transparent !default;
$c-tabs__nav-button--bg-color--open:                                  transparent !default;
$c-tabs__nav-button--color:                                           $f-color__brand-primary !default;
$c-tabs__nav-button--after-color--open:                               $f-color__brand-accent !default;

$c-tabs__nav-button--color--hover--secondary:                         $f-color__brand-accent !default;
$c-tabs__nav-button--color--open--secondary:                          $f-color__brand-primary !default;

$c-tabs__nav-button--bg-color--hover--featured:                       $f-color__brand-accent--semi-dark !default;
$c-tabs__nav-button--bg-color--open--featured:                        $f-color__brand-accent--semi-dark !default;
$c-tabs__nav-button--bg-color--featured:                              $f-color__brand-accent !default;

$c-tabs__swipe-button--icon-color:                                    $f-color__brand-primary !default;
$c-tabs__swipe-button--icon-color--hover:                             $f-color__brand-accent !default;

$c-tabs__nav-button-icon--font-size:                                  1.25em !default;
$c-tabs__nav-button-icon--font-size--secondary:                       16px !default;
$c-tabs__nav-button-icon--top--secondary:                             -2px !default;