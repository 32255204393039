/* -------------------------------------------------------------------------
 * PRICE
 */

$c-price__info-icon--margin-left:               2px !default;
$c-price__info-icon--color:                     $f-color__brand-primary !default;


// Size Modifiers
// --------------------------------

// Small modifier

$c-price__info--sp-stack--small:                $f-space--none !default;


$c-price__label-grayed--margin-top:                                       $f-space--tiny !default;
$c-price__label-grayed--line-height:                                      1.2 !default;
$c-price__label-grayed--breakpoint:                                       $f-breakpoint--sm !default;
$c-price__label-grayed--font-size:                                        10px !default;
