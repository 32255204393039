// --------------------------------------------------
// Color schemes overrides
// --------------------------------------------------

.cs-brand-primary {

  .c-icon-row__link {
    color: $c-icon-row__link--color--cs-brand-primary;

    &:hover {
      color: $c-icon-row__link--color-hover--cs-brand-primary;
    }
  }

}
