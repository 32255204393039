
/* -------------------------------------------------------------------------
 * COLORS BRAND
 *
 * Define brand colors and its priorities
 */


// Main brand colors

$f-color__brand-primary:                  #0080ab !default;
$f-color__brand-secondary:                #ffda1a !default;
$f-color__brand-accent:                   #f6a6b3 !default;

// Primary shades

$f-color__brand-primary--ultra-light:     #f3f7f8 !default;
$f-color__brand-primary--light:           #dfeef1 !default;
$f-color__brand-primary--semi-light:      #7fbfd5 !default;

$f-color__brand-primary--semi-dark:       #027298 !default;
$f-color__brand-primary--dark:            #004c66 !default;

// Secondary shades

$f-color__brand-secondary--ultra-light:   #fff8d1 !default;
$f-color__brand-secondary--light:         #ffec8c !default;
$f-color__brand-secondary--semi-light:    #ffe55e !default;

$f-color__brand-secondary--semi-dark:     #eac90a !default;
$f-color__brand-secondary--dark:          #cbaf19 !default;

// Accent shades

$f-color__brand-accent--ultra-light:      #fef2f4 !default;
$f-color__brand-accent--light:            #fce0e5 !default;
$f-color__brand-accent--semi-light:       #f9c5cd !default;

$f-color__brand-accent--semi-dark:        #e37d8e !default;
$f-color__brand-accent--dark:             #cb596c !default;
