// Flow button has a different font family
.c-btn--flow,
a.c-btn--flow {
  font-family: $c-btn-flow--font-family;
  font-size: $c-btn-flow--font-size;
  font-weight: $c-btn-flow--font-weight;
  @include t-text-crop($c-btn-flow--font-family--crop, $c-btn--line-height);

  .c-btn__icon,
  .c-btn__loader {
    margin-top: $c-btn-flow__icon--vertical-adjust;
  }
}
