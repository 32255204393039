/* -------------------------------------------------------------------------
* Textbox Variables
*
*/

$c-textbox--padding-right--icon-right:               $f-space--large + $f-space--small !default;

$c-textbox__label--text-transform:                   none !default;
$c-textbox__label--space-stack:                      $f-space--tiny !default;

$c-textbox__element--shadow--focus:                  $f-shadow--glow $e-form-elements--focus--border-color !default;

$c-textbox--sp-inset-h:                              $f-space--small !default;
$c-textbox--sp-inset-v:                              $f-space--small  !default;

// Modifiers
// -----------------------------------------

// Highlighted
$c-textbox__element--highlighted--color:              $e-form-elements__inner-element--color !default;
