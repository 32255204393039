/* -------------------------------------------------------------------------
 * BOOKING BASKET
 *
 */

.w-booking-basket__price-total--title, .w-booking-basket__price-total--amount {
  color: $w-booking-basket__price-total--color;
}

.w-booking-basket__services--header-icon {
  color: $w-booking-basket__services--header-icon--color;
}

.w-booking-basket__price-person--amount {
  color: $w-booking-basket__price-person--amount--color;
}