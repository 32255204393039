/* -------------------------------------------------------------------------
 * MODAL
 *
 */

.c-modal-v2{
    &.has-logo{
        @each $_breakpoint in $c-side-drawer--breakpoints {
            @include t-mq($from: $_breakpoint) {
                .c-modal-v2__header-title{
                    display: flex;

                    .c-modal-v2__header-logo-img{
                        margin-right: $c-modal-v2_eliza__header--logo-mr;
                        margin-bottom: 0px;
                    }
                } 
            }
        }
        .c-modal-v2__header-logo-img{
            height: $c-modal-v2_eliza__header--logo-height;
        }
    }
}