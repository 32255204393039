/* -------------------------------------------------------------------------
 * SITE FOOTER
 *
 */

.l-site-footer.cs-brand-primary .c-nav {
  text-transform: unset;
  font-family: $l-site-footer__c-nav--font-family--cs-brand-primary;
}

.l-site-footer__bottom.cs-brand-accent {
  .c-nav__link:hover {
  background-color: transparent;
  color: $l-site-footer-bottom__c-nav-link--color-hover--cs-brand-accent;
  
  
  }

  .c-nav--inline .o-swipe-btn {
    color: $l-site-footer-bottom__o-swipe-btn--color--cs-brand-accent;
    

    &:hover {
      color: $l-site-footer-bottom__o-swipe-btn--color-hover--cs-brand-accent;
      
    }
  }

  .o-swipe-btn--left {
    
    background: $l-site-footer-bottom__o-swipe-btn-left--bg-color--cs-brand-accent;
  }
  .o-swipe-btn--right {
    background: $l-site-footer-bottom__o-swipe-btn-right--bg-color--cs-brand-accent;
  }
}
