/* -------------------------------------------------------------------------
 * PRICE TABLE
 *
 */

.c-price-table__cell--price {
  &:active .c-price__value,
  &.is-active .c-price__value {
    color: $c-price-table__cell--price--color;
  }
}

.c-price-table__row,
.c-price-table__row.is-highlighted {
  .c-price-table__cell--price.is-active,
  .c-price-table__cell--price:active {
    &:before {
      border: none;
      left: 0;
      right: 0;
    }
  }
}

.c-price-table__navigation:hover {
  color: $c-price-table__navigation--hover-color;
}