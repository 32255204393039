
/* -------------------------------------------------------------------------
 * THEME CHILD-FRIENDLY Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-diamond--enabled {
  .cs-theme-diamond {
    @include color-scheme($cs-theme-diamond)
  }
}
