/* -------------------------------------------------------------------------
 * FAVORITE
 *
 */

.c-favorite-btn {
  &::before {
    transition: color $c-favorite-btn--duration;
  }

  &:hover::before {
    color: $c-favorite-btn--before-hover-color;
    
  }

  &.is-active {
    color: $c-favorite-btn--before-color--active;
    &:hover::before {
      color: $c-favorite-btn--before-hover-color--active;
    }
  }
  
}