/* -------------------------------------------------------------------------
 * HEADING variables
 *
 */


// Material default properties

$m-heading__properties: (
  "font-family"       : $f-font-family__secondary,
  "font-family-crop"  : $f-font-family__secondary--crop,
  "font-weight"       : 700,
  "font-size"         : $f-font-size--xl,
  "line-height"       : $e-headings__line-height,
  "text-transform"    : uppercase,
  "letter-spacing"    : 0.5px,
  "color"             : $f-color__brand-primary--semi-dark
) !default;

// Material Size Modifiers

$m-heading__mod-sizes--enabled : true !default;
$m-heading__mod-sizes          : (
  "micro": (
    "font-family"       : $e-document__body--font-family,
    "font-family-crop"  : $e-document__body--font-family--crop,
    "font-weight"       : 700,
    "font-size"         : $f-font-size--s,
    "line-height"       : $e-document__body--line-height,
    "text-transform"    : lowercase,
    "letter-spacing"    : normal,
    "color"             : $f-color__neutral-grey--dark
  ),
  "tiny": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 700,
    "font-size"         : 14px,
    "line-height"       : $e-headings__line-height,
    "text-transform"    : uppercase,
    "letter-spacing"    : 0.5px,
    "color"             : $f-color__neutral-grey--dark
  ),
  "small": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 700,
    "font-size"         : 16px,
    "line-height"       : $e-headings__line-height,
    "text-transform"    : uppercase,
    "letter-spacing"    : 0.5px,
    "color"             : $f-color__brand-primary--semi-dark
  ),
  "medium"              : $m-heading__properties,
  "large": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-weight"       : 700,
    "font-size"         : $f-font-size--xxl,
    "line-height"       : $e-headings__line-height,
    "text-transform"    : uppercase,
    "letter-spacing"    : 0.5px,
    "color"             : $f-color__brand-primary--semi-dark
  ),
  "huge": (
    "font-family"       : $f-font-family__secondary,
    "font-family-crop"  : $f-font-family__secondary--crop,
    "font-size"         : $f-font-size--xxxl,
    "font-weight"       : 700,
    "line-height"       : $e-headings__line-height,
    "text-transform"    : uppercase,
    "letter-spacing"    : 0.5px,
    "color"             : $f-color__brand-primary--semi-dark
  )
) !default;
