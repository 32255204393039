/* -------------------------------------------------------------------------
 * PROMOTION IMAGE
 *
 */

.c-promotion-image__content--title, .c-promotion-image__content--body {
  color: $c-promotion-image__content--text-color;
  
}

.c-promotion-image__group[data-items-number="3"] {
  [data-c-promotion-image__index="2"], [data-c-promotion-image__index="3"] {
    .c-promotion-image__content--title {
      @include t-mq($from: $f-breakpoint--sm) {
        color: $c-promotion-image__content--text-color;
      }
    }
  }
}
