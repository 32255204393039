// Color schemes overrides
// -----------------------------------------

// Brand Primary
// -----------------------------------------

.cs-brand-primary {
  .c-nav {
    text-transform: uppercase;
    font-family: $c-nav--cs-brand-primary--font-family;

    .c-nav__link {
      color: $c-nav__link--cs-brand-primary--color;

      &:hover {
        color: $c-nav__link--cs-brand-primary--hover-color;
        background-color: $c-nav__link--cs-brand-primary--bg-color;
      }
    }
  }

  .c-nav--dropdown {
    .c-nav__list {
      &>.c-nav__item>.c-nav__link {
        font-weight: $c-nav--cs-brand-primary--font-weight;
      
        &:hover {
          color: $c-nav__link--cs-brand-primary--hover-color;
        }
      }

      &[data-c-nav__level="1"]>.c-nav__item {
    
        &:not(:last-child) {
          margin-right: $c-nav__item--cs-brand-primary--margin-right;
        }
    
        &.is-opened>.c-nav__link {
          color: $c-nav__link--cs-brand-primary--color;
        }
      }

      &:not([data-c-nav__level="1"]) {
        background-color: $c-nav__link--cs-brand-primary--bg-color;
        font-size: $c-nav__link-dropdown--cs-brand-primary--font-size;
  
        .c-nav__item.is-active>.c-nav__link {
          border-left-color: $c-nav__link-active--cs-brand-primary--border-color;
          background-color: unset;
          font-weight: $c-nav--cs-brand-primary--font-weight;
        }
  
        .c-nav__list.c-nav__list--sibling-children:not([data-c-nav__level="1"]) .c-nav__item:first-child>.c-nav__link:before,
        .c-nav__link:before {
          display: none;
        }
      }
    }

    .c-nav__item {
      &.has-child>.c-nav__link {
        color: $c-nav__link--cs-brand-primary--color;
  
        &:hover {
          color: $c-nav__link--cs-brand-primary--hover-color;
        }
      }

      &.is-opened>.c-nav__link {
        background-color: $c-nav__link--cs-brand-primary--bg-color;
      }
    }
  }

  .c-nav__list:not([data-c-nav__level="1"]) .c-nav__item>.c-nav__link   {
    font-size: $c-nav__link-dropdown--cs-brand-primary--font-size;
  }

  .c-nav__form-submit {
    text-transform: inherit;
    
    &:hover {
      background-color: unset;
      color: $c-nav__link--cs-brand-primary--hover-color;
    }
  }
  
  .l-site-header__main-right .c-nav__list[data-c-nav__level="3"],
  .l-site-header__main-right .c-nav__list[data-c-nav__level="4"] {
    text-transform: uppercase;
  }

  .c-side-drawer {
    .c-nav__link {
      font-weight: $c-nav--cs-brand-primary--font-weight;
    }

    .c-nav--block {
      & .c-nav__item.has-child>.c-nav__link {
        color: $c-nav__link--cs-brand-primary--color;
        &:hover {
          color: $c-nav__link--cs-brand-primary--hover-color;
        }
      }

      &.c-nav--multilevel .c-nav__item--back>.c-nav__link {
        color: $c-nav__link--cs-brand-primary--color;

        &:hover {
          color: $c-nav__link--cs-brand-primary--hover-color;
        }
      }

      & .c-nav__item.is-active>.c-nav__link {
        background-color: unset;
        font-weight: $c-nav--cs-brand-primary--font-weight;
      }
    }
    
    .c-nav__item {
      border-color: $c-nav__item--cs-brand-primary--border-color; 
    }

    .c-favorite-collector:after {
      background-color: $c-nav__favorite-collector--cs-brand-primary--background-color;
      color: $c-nav__favorite-collector--cs-brand-primary--color;
      transition: color $c-nav__favorite-collector--cs-brand-primary--transition, background-color $c-nav__favorite-collector--cs-brand-primary--transition;
    }

    .c-nav__link:hover.c-favorite-collector:after {
      background-color: $c-nav__favorite-collector--cs-brand-primary--background-color-hover;
      color: $c-nav__favorite-collector--cs-brand-primary--color-hover;
    }
  }
}

// Brand Accent
// -----------------------------------------

.cs-brand-accent {
  .c-nav {
    text-transform: lowercase;
    font-family: $c-nav--cs-brand-accent--font-family;
    font-weight: $c-nav--cs-brand-accent--font-weight;

    .c-btn--flat {
      color: $c-nav__link--cs-brand-accent--color;
      background-color: $c-nav__btn-flat--cs-brand-accent--background-color;

      &:hover {
        color: $c-nav__link--cs-brand-accent--hover-color;
      }
    }
  }

  .c-nav--dropdown {
    .c-nav__list[data-c-nav__level="2"] {
      border-top: none;
    }

    .c-nav__list[data-c-nav__level="1"] > .c-nav__item {

      &:not(:last-child) {
        margin-right: $c-nav__item--cs-brand-accent--margin-right;
      }

      & > .c-nav__link {
        color: $c-nav__link--cs-brand-accent--color;

        &:hover {
          color: $c-nav__link--cs-brand-accent--hover-color;
        }
      }
    }
  }

  .c-nav--inline {
    .c-nav__link {
      color: $c-nav__link--cs-brand-accent--color;

      &:hover {
        color: $c-nav__link--cs-brand-accent--hover-color;
      }
    }
  }
}

