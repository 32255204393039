/* -------------------------------------------------------------------------
 * LOADER VARIABLES
 */

$c-brand-loader--width:                           150px !default;
$c-brand-loader--easing:                          $f-easing--linear !default;
$c-brand-loader__wrapper--animation-time:         8s !default;
$c-brand-loader__sun--fill:                       $f-color__brand-secondary !default;
$c-brand-loader__inside--fill:                    $f-color__neutral-white !default;


// Size modifiers
// -----------------------------------------

$c-brand-loader--width--tiny:                     24px !default;
