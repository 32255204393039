/* -------------------------------------------------------------------------
 * LINKS VARIABLES
 *
 */

$e-links__color:                          $f-color__brand-primary !default;
$e-links__hover-color:                    $f-color__brand-primary--dark !default;

$e-links__decoration:                     none !default;
$e-links__font-weight:                    700 !default;
$e-links__hover-decoration:               underline !default;
