
/* -------------------------------------------------------------------------
 * FONTS
 *
 * Font families & fallback
 *
 */

// Web fonts variable. Used to preload load them
$web-fonts: ();


// Font imports

@import "foundations__font-families--verdana";
@import "foundations__font-families--kelson";


// Font families

$f-font-family__primary:          $f-font-family__verdana, $f-font-family__verdana--fallback !default;
$f-font-family__secondary:        $f-font-family__kelson, $f-font-family__kelson--fallback !default;

$f-font-family__primary--crop:    $f-font-family__verdana--crop !default;
$f-font-family__secondary--crop:  $f-font-family__kelson--crop !default;


// FONT FAMILIES MAP

$f-font-families: (
  "primary":    $f-font-family__primary,
  "secondary":  $f-font-family__secondary,
) !default;

$f-font-families--crops: (
  "primary":    $f-font-family__primary--crop,
  "secondary":  $f-font-family__secondary--crop,
) !default;
