
/* -------------------------------------------------------------------------
 * THEME CHILD-FRIENDLY Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-budget--enabled {
  .cs-theme-budget {
    @include color-scheme($cs-theme-budget)
  }
}
