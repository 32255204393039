/* -------------------------------------------------------------------------
 * CONTENT USPS
 *
 */

.w-content-usps--secondary-color-checkmark {
  .c-bullet-list__text {
    color: $w-content-usps--bullet-text-secondary-color;
  }
}

.w-content-usps__usp-block-title {
  @include m-heading($w-content-usps__usp-block-title--properties);
}