
$f-font-family__kelson:               'Kelson' !default;
$f-font-family__kelson--fallback:     Impact, Charcoal, sans-serif !default;

$f-font-family__kelson--crop: (
    "font-size":      40,
    "line-height":    1,
    "top":            6,
    "bottom":         5
) !default;

@font-face {
  font-family: $f-font-family__kelson;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Kelson'), local('Kelson Regular'), local('Kelson-Regular'),
  url('#{$s-config__path--fonts}/kelson/kelson.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/kelson/kelson.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("kelson": "#{$s-config__path--fonts}/kelson/kelson.woff2"));
}

@font-face {
  font-family: $f-font-family__kelson;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Kelson Medium'), local('Kelson-Medium'),
  url('#{$s-config__path--fonts}/kelson/kelson--medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/kelson/kelson--medium.woff') format('woff'); /* Modern Browsers */
}


@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("kelson--bold": "#{$s-config__path--fonts}/kelson/kelson--medium.woff2"));
}

