/* -------------------------------------------------------------------------
 * LINKS
 *
 * Define some minimal links styles
 *
 */

a {
  color: $e-links__color;
  font-weight: $e-links__font-weight;
  text-decoration: $e-links__decoration;

  @if ($e-links__hover-color != $e-links__color or $e-links__decoration != $e-links__hover-decoration) {
    &:hover {

      @if ($e-links__hover-color != $e-links__color) {
        color: $e-links__hover-color;
      }

      @if ($e-links__decoration != $e-links__hover-decoration) {
        text-decoration: $e-links__hover-decoration;
      }
    }
  }
}
