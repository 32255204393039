/* -------------------------------------------------------------------------
 * COLLECTION TEASER
 *
 */

.c-collection-teaser {
  .c-label {
    position: absolute;
    left: $c-collection-teaser__label--left-position;
    top: $c-collection-teaser__label--top-position;
    z-index: $c-collection-teaser__label--z-index;
  }
}
