/* -------------------------------------------------------------------------
 * PRICE
 */

.c-price {
  .c-price__info:after {
    margin-top: -0.45em;
  }
}



// Size Modifiers
// --------------------------------

// Small and large modifier

.c-price--small, .c-price--large {
  .c-price__info:after {
    margin-top: -0.25em;
  }
}

/* ---------
 * Mandatory booking related costs related styles
 */
 .c-price__label.c-price__label-grayed {
  line-height: $c-price__label-grayed--line-height;
  display: inline-block;
  margin-top: $c-price__label-grayed--margin-top;
  font-size: $c-price__label-grayed--font-size;
}

@include t-mq($until: $c-price__label-grayed--breakpoint) {
  .w-acco-lister__package-item--listed {
    .c-price__label.c-price__label-grayed {
      font-size: $c-price__label-grayed--font-size;
    }
    .c-breadcrumbs__item,
    .c-breadcrumbs__link {
      font-size: $c-price__label-grayed--font-size;
    }
  }
}
/* -------- */