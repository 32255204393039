/* -------------------------------------------------------------------------
 * FAVORITE VARS
 *
 */

$c-favorite-btn--border-radius:                         0 !default;
$c-favorite-btn--bg-color:                              $f-color__brand-primary !default;
$c-favorite-btn--color:                                 $f-color__neutral-white !default;
$c-favorite-btn--bg-color--hover:                       $f-color__brand-primary !default;
$c-favorite-btn--before-hover-color:                    $f-color__brand-accent !default;
$c-favorite-btn--before-color--active:                  $f-color__brand-accent !default;
$c-favorite-btn--before-hover-color--active:            $f-color__brand-accent--semi-dark !default;
