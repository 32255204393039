
/* -------------------------------------------------------------------------
 * COLORS OTHER
 *
 * Define all other colors
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main other colors

$f-color__digital-blue:             #0070d2 !default;
$f-color__flow:                     #f70080 !default;
$f-color-neutral-alternate:         #fafafa !default;

// Other color map

$f-colors-other: (
  "digital-blue":                   $f-color__digital-blue,
  "flow":                           $f-color__flow,
  "neutral-alternate":              $f-color-neutral-alternate,
) !default;

