
/* -------------------------------------------------------------------------
 * SHADOWS
 *
 * Define box and text shadows
 * The values here are for sample proposes, consider add your owns
 *
 */

// Main shadow

$f-shadow:                    0 0 42px 0 !default;
$f-shadow__color:             $f-color__neutral-black !default;
$f-shadow__opacity:           0.14 !default;

// Shadow variants
$f-shadow--elevated:          1px 1px 5px 0px !default;
$f-shadow--text:              1px 1px 5px !default;

// Shadow opacity variants

$f-shadow__opacity--text:     0.85 !default;
