/* -------------------------------------------------------------------------
 * TRANSPORT TICKET
 *
 */

.c-transport-ticket__detailed-info-departure, .c-transport-ticket__detailed-info-arrival {
  .m-heading {
    color: $c-transport-ticket__detailed-info;
  }
}

.c-transport-ticket__airline-name {
  text-transform: none;
}