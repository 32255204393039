/* -------------------------------------------------------------------------
 * NAV
 */

 // Color schemes overrides
 // --------------------------------------------------

 // Brand Primary
 // ---------------------

$c-nav--cs-brand-primary--font-family:                                        $f-font-family__secondary !default;
$c-nav--cs-brand-primary--font-weight:                                        700 !default;

$c-nav__link--cs-brand-primary--color:                                        $f-color__neutral-white !default;
$c-nav__link--cs-brand-primary--hover-color:                                  $f-color__brand-accent !default;
$c-nav__link--cs-brand-primary--bg-color:                                     $f-color__brand-primary !default;

$c-nav__link-dropdown--cs-brand-primary--font-size:                           $f-font-size--s !default;

$c-nav__list--dropdown-min-width:                                             300px !default;
$c-nav__list--dropdown-max-width:                                             300px !default;

$c-nav--dropdown--sp-inset-v:                                                 $f-space--small + $f-space--tiny !default;
$c-nav--dropdown--sp-inset-h:                                                 $f-space--small + $f-space--tiny !default;

$c-nav__link-active--cs-brand-primary--border-color:                          $f-color__brand-accent !default;

$c-nav__item--cs-brand-primary--margin-right:                                 $f-space--medium !default;

$c-nav__favorite-collector--cs-brand-primary--background-color:               $f-color__neutral-white !default;
$c-nav__favorite-collector--cs-brand-primary--color:                          $f-color__brand-primary !default;
$c-nav__favorite-collector--cs-brand-primary--transition:                     $f-duration--normal $f-easing--base !default;
$c-nav__favorite-collector--cs-brand-primary--background-color-hover:         $f-color__brand-accent !default;
$c-nav__favorite-collector--cs-brand-primary--color-hover:                    $f-color__neutral-white !default;

// Brand Accent
 // ---------------------

$c-nav--cs-brand-accent--font-family:                                         $f-font-family__primary !default;
$c-nav--cs-brand-accent--font-weight:                                         400 !default;

$c-nav__link--cs-brand-accent--color:                                         $f-color__brand-primary--dark !default;
$c-nav__link--cs-brand-accent--hover-color:                                   $f-color__brand-primary !default;

$c-nav__item--cs-brand-accent--margin-right:                                  $f-space--small !default;

$c-nav__btn-flat--cs-brand-accent--background-color:                          $f-color__brand-accent !default;
