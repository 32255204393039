/* -------------------------------------------------------------------------
 * LOADER
 *
 */

.c-brand-loader {
  width: $c-brand-loader--width;
}

.c-brand-loader__wrapper {
  animation: c-brand-loader $c-brand-loader__wrapper--animation-time infinite $c-brand-loader--easing;
  transform-origin: 50% 50%;
}

.c-brand-loader__circle {
  fill: transparent;
}

.c-brand-loader__sun {
  fill: $c-brand-loader__sun--fill;
}

.c-brand-loader__inside {
  fill: $c-brand-loader__inside--fill;
}


@keyframes c-brand-loader {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}



// Size modifiers
// -----------------------------------------

.c-brand-loader--tiny {
  width: $c-brand-loader--width--tiny;
}
