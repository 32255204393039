/* -------------------------------------------------------------------------
 * LABEL
 *
 */

.c-label, .c-label.c-label--badge {
  font-weight: $c-label--font-weight;
}

.c-label--badge, 
.c-label--collection:not(.cs-theme-gold):not(.cs-theme-gogo-toppers){
	color: $c-label-variants--color;
}

.cs-brand-primary--white.c-label--badge,
.cs-brand-secondary--white.c-label--badge,
.cs-brand-accent--white.c-label--badge {
  color: $c-label-variants-white--color;
}


.cs-brand-accent.c-label--badge {
  color: $c-label-variants-accent--color;
}
