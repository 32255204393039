/* -------------------------------------------------------------------------
 * Price variables
 *
 */

$m-price--font-family:                          $f-font-family__secondary !default;
$m-price--spacing-inline:                       $f-space--tiny * .5 !default;

// Modifiers
// -----------------------------------------------

$m-price--small--font-family:                   $f-font-family__primary !default;
$m-price--small--letter-spacing:                normal !default;
