/* -------------------------------------------------------------------------
 * BUTTON
 */

// Default button
// -------------------------------
$c-btn--color:                                  $cs-brand-secondary--white--heading !default;
$c-btn--bg-color:                               $cs-brand-secondary--white--background !default;
$c-btn--border-color:                           $f-color__brand-accent !default;
$c-btn--font-size:                              $f-font-size--s !default;
$c-btn--font-family:                            $f-font-family__primary !default;
$c-btn--font-family--crop:                      $f-font-family__primary--crop !default;
$c-btn--spacing-inset:                          $f-space--medium !default;

$c-btn__icon--vertical-adjust:                  -.2em !default;
$c-btn__icon--size:                             1.5em !default;

// Hover
$c-btn--hover--color:                           $f-color__neutral-white !default;
$c-btn--hover--bg-color:                        $f-color__brand-accent !default;
$c-btn--hover--border-color:                    $f-color__brand-accent !default;

// Pressed
$c-btn--pressed--color:                         $f-color__neutral-white !default;
$c-btn--pressed--bg-color:                      $f-color__brand-accent--semi-dark !default;
$c-btn--pressed--border-color:                  $f-color__brand-accent--semi-dark !default;

// Focus
$c-btn--focus--shadow:                          none !default;


// Variants > Secondary
// -------------------------------
$c-btn-secondary--bg-color:                     $f-color__brand-primary !default;
$c-btn-secondary--border-color:                 $f-color__brand-primary !default;
$c-btn-secondary--shadow:                       none!default;

// Hover
$c-btn-secondary--hover--bg-color:              $f-color__brand-primary--semi-dark !default;
$c-btn-secondary--hover--border-color:          $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn-secondary--pressed--bg-color:            $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--border-color:        $f-color__brand-primary--dark !default;
$c-btn-secondary--pressed--shadow:              none !default;

// Focus
$c-btn-secondary--focus--shadow:                none !default;



// Variants > Neutral
// -------------------------------
$c-btn-neutral--color:                          $f-color__neutral-grey--dark !default;
$c-btn-neutral--bg-color:                       $f-color__neutral-white !default;
$c-btn-neutral--border-color:                   $f-color__neutral-grey--semi-dark !default;

// Hover
$c-btn-neutral--hover--color:                   $c-btn-neutral--color !default;
$c-btn-neutral--hover--bg-color:                $f-color__neutral-grey--light !default;
$c-btn-neutral--hover--border-color:            $c-btn-neutral--border-color !default;

// Pressed
$c-btn-neutral--pressed--color:                 $c-btn-neutral--color !default;
$c-btn-neutral--pressed--bg-color:              $f-color__neutral-grey--semi-light !default;
$c-btn-neutral--pressed--border-color:          $c-btn-neutral--border-color !default;

// Focus
$c-btn-neutral--focus--shadow:                  none !default;


// Variants > Flow
// -------------------------------

$c-btn-flow--color:                             $cs-brand-accent--text !default;
$c-btn-flow--bg-color:                          $cs-brand-accent--background !default;
$c-btn-flow--border-color:                      $cs-brand-accent--border !default;
$c-btn-flow--shadow:                            none !default;

$c-btn-flow--font-size:                         $f-font-size--m !default;
$c-btn-flow--font-family:                       $f-font-family__secondary !default;
$c-btn-flow--font-family--crop:                 $f-font-family__secondary--crop !default;
$c-btn-flow--font-weight:                       700 !default;

$c-btn-flow__icon--vertical-adjust:             -.1em !default;

// Hover
$c-btn-flow--hover--color:                      $c-btn-flow--color !default;
$c-btn-flow--hover--bg-color:                   darken($c-btn-flow--bg-color, 8%) !default;
$c-btn-flow--hover--border-color:               darken($c-btn-flow--border-color, 8%) !default;

// Pressed
$c-btn-flow--pressed--color:                    $c-btn-flow--color !default;
$c-btn-flow--pressed--bg-color:                 darken($c-btn-flow--bg-color, 15%) !default;
$c-btn-flow--pressed--border-color:             darken($c-btn-flow--border-color, 15%) !default;
$c-btn-flow--pressed--shadow:                   none  !default;

// Focus
$c-btn-flow--focus--shadow:                     none !default;


// Variants > Flat
// -------------------------------
$c-btn-flat--color:                             $f-color__brand-primary !default;
$c-btn-flat--bg-color:                          $f-color__neutral-white--transparent !default;
$c-btn-flat--border-color:                      $f-color__neutral-white--transparent !default;
$c-btn-flat--shadow:                            none !default;

// Hover
$c-btn-flat--hover--color:                      $f-color__brand-primary--semi-light !default;
$c-btn-flat--hover--bg-color:                   rgba($f-color__neutral-white, .1) !default;
$c-btn-flat--hover--border-color:               rgba($f-color__neutral-white, .1) !default;

// Pressed
$c-btn-flat--pressed--color:                    darken($c-btn-flat--color, 10%) !default;
$c-btn-flat--pressed--bg-color:                 rgba($f-color__neutral-white, .2) !default;
$c-btn-flat--pressed--border-color:             rgba($f-color__neutral-white, .2) !default;


// Color schemes overrides
// -------------------------------
$c-btn-icon-only--color--cs-primary:            $f-color__brand-secondary !default;
