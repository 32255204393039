/* -------------------------------------------------------------------------
 * TABS
 *
 */

.c-tabs__nav-button {
  @include m-heading--complete($c-tabs__nav-item--font-properties);
  color: $c-tabs__nav-button--color;
  
  .c-btn__icon:before {
    font-size: $c-tabs__nav-button-icon--font-size;
  }
}

.c-tabs__nav--secondary .c-btn__icon {
  font-size: $c-tabs__nav-button-icon--font-size--secondary;

  &:before {
    top: $c-tabs__nav-button-icon--top--secondary;
  }
}
