
/* -------------------------------------------------------------------------
 * FONT SIZES
 *
 * Values should be expressed in PX.
 * If you need a relative unit based on that values, use the core functions
 * on used context.
 */


// Main font sizes

$f-font-size--xxs:                9px !default;
$f-font-size--xs:                 11px !default;
$f-font-size--s:                  13px !default;
$f-font-size--m:                  $s-core__font-size !default;
$f-font-size--l:                  17px !default;
$f-font-size--xl:                 22px !default;
$f-font-size--xxl:                30px !default;
$f-font-size--xxxl:               34px !default;
