
/* -------------------------------------------------------------------------
 * THEME CHILD-FRIENDLY Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-springbreak--enabled {
  .cs-theme-springbreak {
    @include color-scheme($cs-theme-springbreak)
  }
}
