/* -------------------------------------------------------------------------
* Calendar Variables
*
*/

$c-calendar__month--color:                              $f-color__brand-primary !default;
$c-calendar__month--color--hover:                       $f-color__brand-primary--semi-light !default;
$c-calendar__month--font-size:                          $f-font-size--l !default;

$c-calendar__day--color:                                $f-color__brand-primary !default;
$c-calendar__day--font-weight:                          400 !default;
$c-calendar__day--hover--bg-color:                      $f-color__brand-primary--light !default;
$c-calendar__day--selected--color:                      $f-color__brand-secondary !default;
$c-calendar__day--selected--hover--color:               $f-color__brand-secondary--light !default;
$c-calendar__day--selected--font-weight:                700 !default;
$c-calendar__day--disabled--selected--bg-color:         $f-color__neutral-grey !default;
$c-calendar__day--disabled--selected--color:            $f-color__neutral-white !default;
$c-calendar__day--disabled--selected--font-weight:      400 !default;

$c-calendar__week-number--color:                        $f-color__neutral-grey--ultra-dark !default;




