/* -------------------------------------------------------------------------
 * LABEL
 *
 */

$c-label--font-weight:                        700 !default;

$c-label-variants--color:                     $f-color__neutral-white !default;
$c-label-variants-white--color:               map_get($cs-brand-primary--white, 'text') !default;
$c-label-variants-accent--color:              map_get($cs-brand-accent, 'text') !default;

$c-label--font-size--sale:                    10px !default;
$c-label--bg-color--sale:                     $f-color__state-danger !default;
