/* -------------------------------------------------------------------------
* Dropdown Variables
*
*/

$c-dropdown__element--shadow--focus:                  $f-shadow--glow $e-form-elements--focus--border-color !default;


// Modifiers
// -----------------------------------------

// Highlighted
$c-dropdown__element--highlighted--value-color:       $e-form-elements__inner-element--color !default;
