/* -------------------------------------------------------------------------
 * PACKAGE ITEM VARS
 */

$c-package-item__body--padding-v:                              $f-space--medium !default;
$c-package-item__body--padding-h:                              $f-space--small !default;

$c-package-item--alignment-fix:                                -1.5 * $f-space--tiny !default;

$c-package-item__rating--color:                                $f-color__brand-secondary--semi-dark !default;

$c-package-item__price-color:                                  $f-color__brand-accent !default;

$c-package-item__title--material:                              map_get($m-heading__mod-sizes, "tiny") !default;
$c-package-item__title--color:                                 $f-color__brand-primary !default;


// Mobile Modifications

$c-package-item__body--listed--padding:                        $f-space--tiny !default;
$c-package-item__body--listed--from-mq--padding:               $f-space--small !default;
