
/* -------------------------------------------------------------------------
 * COLORS STATE
 *
 * Define all state colors
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main state colors

$f-color__state-success:                  #81d4ac !default;
$f-color__state-info:                     #71aed1 !default;
$f-color__state-danger:                   #e56957 !default;
$f-color__state-warning:                  #f5a623 !default;

// Success shades

$f-color__state-success--ultra-light:     #e7f8f4 !default;
$f-color__state-success--light:           #cff0e9 !default;
$f-color__state-success--semi-light:      #a1dfc1 !default;

$f-color__state-success--semi-dark:       #5ac792 !default;
$f-color__state-success--dark:            #3eaa75 !default;

// Info shades

$f-color__state-info--ultra-light:        #e6f4fa !default;
$f-color__state-info--light:              #d8e9f2!default;
$f-color__state-info--semi-light:         #b1d3e6 !default;

$f-color__state-info--semi-dark:          #5295bc !default;
$f-color__state-info--dark:               #397ca4 !default;

// Danger shades

$f-color__state-danger--ultra-light:      #fde0db !default;
$f-color__state-danger--light:            #fac0b7 !default;
$f-color__state-danger--semi-light:       #f4806f !default;

$f-color__state-danger--semi-dark:        #d94b36 !default;
$f-color__state-danger--dark:             #cb2b13 !default;

// Warning shades

$f-color__state-warning--ultra-light:     #fef6e9 !default;
$f-color__state-warning--light:           #fce4bd !default;
$f-color__state-warning--semi-light:      #f8c065 !default;

$f-color__state-warning--semi-dark:       #e08c02 !default;
$f-color__state-warning--dark:            #e08c02 !default;


$f-colors-state: (
  "success":                            $f-color__state-success,
  "info":                               $f-color__state-info,
  "danger":                             $f-color__state-danger,
  "success--ultra-light":               $f-color__state-success--ultra-light,
  "success--light":                     $f-color__state-success--light,
  "success--semi-light":                $f-color__state-success--semi-light,
  "success--semi-dark":                 $f-color__state-success--semi-dark,
  "success--dark":                      $f-color__state-success--dark,
  "info--ultra-light":                  $f-color__state-info--ultra-light,
  "info--light":                        $f-color__state-info--light,
  "info--semi-light":                   $f-color__state-info--semi-light,
  "info--semi-dark":                    $f-color__state-info--semi-dark,
  "info--dark":                         $f-color__state-info--dark,
  "warning--ultra-light":               $f-color__state-warning--ultra-light,
  "warning--light":                     $f-color__state-warning--light,
  "warning--semi-light":                $f-color__state-warning--semi-light,
  "warning--semi-dark":                 $f-color__state-warning--semi-dark,
  "warning--dark":                      $f-color__state-warning--dark,
  "danger--ultra-light":                $f-color__state-danger--ultra-light,
  "danger--light":                      $f-color__state-danger--light,
  "danger--semi-light":                 $f-color__state-danger--semi-light,
  "danger--semi-dark":                  $f-color__state-danger--semi-dark,
  "danger--dark":                       $f-color__state-danger--dark,
) !default;