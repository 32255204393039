/* -------------------------------------------------------------------------
 * SLIDER VARS
 */

// Slider arrows

$c-slider__btn-arrow--size:                             ($f-space--medium + $f-space--tiny) !default;

$c-slider__btn-arrow_icon--left-fix:                    $f-space--tiny !default;
$c-slider__btn-arrow_icon--right-fix:                   $f-space--tiny !default;

$c-slider__nav-item--width:                             $f-space--tiny !default;

$c-slider__btn-arrow--bg-color:                         $f-color__brand-primary--semi-dark !default;
$c-slider__nav-item--bg-color:                          $f-color__brand-primary--semi-dark !default;

$c-slider__nav-item--separation:                        $f-space--tiny !default;

$c-slider__thumbnail--info-icon-center-top:             26% !default;
$c-slider__thumbnail--info-icon-center-top-tablet:      20% !default;
$c-slider__thumbnail--info-icon-center-right-tablet:    26% !default;