
/* -------------------------------------------------------------------------
 * THEME CHILD-FRIENDLY Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-gold--enabled {
  .cs-theme-gold {
    @include color-scheme($cs-theme-gold)
  }
}
