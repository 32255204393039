/* -------------------------------------------------------------------------
 * FLIGHT BUS SELECTOR
 *
 */

.w-flight-bus-selector__heading-date {
  font-weight: 400;
  text-transform: unset;
  color: $f-color__neutral-grey--dark;
}

.w-flight-bus-selector__flexible-regular-flights-tabs .c-btn__icon:before {
  top: $w-flight-bus-selector__flexible-regular-tabs__icon--top;
}  
