
/* -------------------------------------------------------------------------
 * COLORS NEUTRAL
 *
 * Neutral colors are the ones without saturation
 * The values here are for sample proposes, consider add your owns
 *
 */


// Black & White

$f-color__neutral-white:                #fff !default;
$f-color__neutral-black:                #000 !default;

// Grey shades (Mid tones are missing intentionally)

$f-color__neutral-grey--ultra-light:    #f6f6f6 !default;
$f-color__neutral-grey--light:          #eeeeee !default;
$f-color__neutral-grey--semi-light:     #d3d9dd !default;
$f-color__neutral-grey:                 #92a1a9 !default;
$f-color__neutral-grey--semi-dark:      #7a7776 !default;
$f-color__neutral-grey--dark:           #333    !default;
$f-color__neutral-grey--ultra-dark:     #000    !default;

// Transparent colors

$f-color__neutral-white--transparent:   rgba($f-color__neutral-white, 0) !default;
$f-color__neutral-black--transparent:   rgba($f-color__neutral-black, 0) !default; 
