/* -------------------------------------------------------------------------
 * SITE FOOTER VARS
 */

$l-site-footer__c-nav--font-family--cs-brand-primary:                               $f-font-family__primary !default;

$l-site-footer-bottom__c-nav-link--color-hover--cs-brand-accent:                    $f-color__brand-primary !default;
$l-site-footer-bottom__o-swipe-btn--color--cs-brand-accent:                         $f-color__neutral-white !default;
$l-site-footer-bottom__o-swipe-btn--color-hover--cs-brand-accent:                   $f-color__brand-primary !default;
$l-site-footer-bottom__o-swipe-btn-left--bg-color--cs-brand-accent:                 linear-gradient(to right, $f-color__brand-accent 40%, rgba($f-color__brand-accent, 0)) !default;
$l-site-footer-bottom__o-swipe-btn-right--bg-color--cs-brand-accent:                linear-gradient(to left, $f-color__brand-accent 40%, rgba($f-color__brand-accent, 0)) !default;